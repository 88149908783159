import React from "react";
import styled from "styled-components";
import Video from '../assets/videos/hands.mp4'
import {Button} from "./button";
import {InlineButtons} from "./styles/global-styles";

const Hero = () => {
  return <HeroContainer>
    <HeroBg>
      <VideoBg src={Video} type="video/mp4" autoPlay loop muted playsInline />
    </HeroBg>
    <HeroContent>
      <HeroItems>
        <HeroH1 colored="#B393FF">O jeito intimista de conectar criador com seguidor. Só vocês, <span>totalmente privado.</span></HeroH1>
        <HeroP>Crie e adquira packs de conteúdo personalizados. Gere links únicos e compartilhe. Pacotes especiais montados para fãs especiais.</HeroP>
        <InlineButtons>
          <HeroButton primary="true" round="true" to="/#creators-section">Para Criadores</HeroButton>
          <HeroButton primary="true" round="true" to="/#followers-section">Para Seguidores</HeroButton>
        </InlineButtons>
      </HeroItems>
    </HeroContent>
  </HeroContainer>
}

export default Hero

const HeroContainer = styled.div`
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  padding: 0 1rem;
  color: #fff;
  margin-top: -80px;

   
`
// GRADIENT IN FRONT OF VIDEO
// :before {
//   content: "";
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   right: 0;
//   left: 0;
//   z-index: 2;
//   background: linear-gradient(
//     100deg, 
//     rgba(0,0,0,0) 0%, 
//     rgba(0,0,0,0.6) 100%
//   ),
//   linear-gradient(100deg, rgba(0,0,0,0.2) 0%, transparent 100%);
// }

const HeroBg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`

const HeroContent = styled.div`
  z-index: 3;
  height: calc(100vh - 80px);
  max-height: 100%;
  max-width: 80vw;
  padding: 0rem calc((100vw - 1300px) / 2);
`

const HeroItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  max-height: 100%;
  padding: 30vh 0 0 0;
  color: #fff;
  line-height: 1.2;

  @media screen and (max-width: 768px) {
    padding: 40vh 0 0 0;
  }
`

const HeroH1 = styled.h1`
  font-size: clamp(1.5rem, 6vw, 2.5rem);
  margin-bottom: 1.5rem;
  font-weight: bold;
  padding: 0 1rem;

  span {
    color: ${({colored}) => (colored ? colored : 'white')};
    font-weight: bold;
  }
`
const HeroP = styled.p`
  font-size: clamp(1rem, 3vw, 1.1rem);
  color: #C0C0C0;
  font-weight: 400;
  line-height: 1.3;
`

const HeroButton = styled(Button)`
  margin: 0 0.5rem 0 0.5rem;
  height: 50px;

  @media screen and (max-width: 768px) {
    margin: 0 0 1rem 0;
  }
`