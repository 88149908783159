import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Creators from "../components/creators-section"
import Followers from "../components/followers-section"

const IndexPage = () => (
  <Layout>
    <Seo title="privetly" lang="pt" />
    <Hero />
    <Creators />
    <Followers />
  </Layout>
)

export default IndexPage
