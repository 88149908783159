import React from 'react'
import AppFaq from './faq-section'
import {ContentContainer, ContentDescription, ContentHeader, ContentWrapper, TopLine} from './styles/global-styles'
import {faqFollowersData} from '../data/followers-data'

const Followers = () => {
  return (
    <div id="followers-section">
      <ContentContainer background="#1A112F">
        <ContentWrapper isLarge="false">
          <TopLine>Para Seguidores</TopLine>
          <ContentHeader>Adquira conteúdos exclusivos de criadores que você ama</ContentHeader>
          <ContentDescription>
            A forma mais simples de dar suporte e acessar conteúdos que te interessam. Nada de assinaturas. Pague por cartão ou boleto, totalmente privado e exclusivo. Tudo entre você e quem você segue.
          </ContentDescription>
        </ContentWrapper>
      </ContentContainer>
      <ContentContainer>
        <ContentWrapper isLarge="true">
          <AppFaq dataToShow={faqFollowersData} title='Perguntas Frequentes (Seguidores)' />
        </ContentWrapper>
      </ContentContainer>
    </div>
  )
}

export default Followers
